//   import toExcel from "@/common/toExcel";

const formatData = function (column, list) {
    return list.map(v => column.map(j => v[j]));
}
/**
 * 
 * @param {*} header 列标题
 * @param {*} column 选取lis的列名
 * @param {*} list 数据源
 * @param {*} name 导出文件名
 * @param {} merges 合并单元格
 */
const toExcel = function (header, column, list, name, merges) {
    require.ensure([], () => {
        const {
            export_json_to_excel
        } = require("@/vendor/Export2Excel");
        let data = formatData(column, list);
        export_json_to_excel(header, data, name, merges);
    });
}


export default toExcel;